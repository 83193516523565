import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingHaccpPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'HACCP'

  const description =
    'L&#39;<b>HACCP</b> (<b><em>Hazard Analysis and Critical Control Points</em></b>) è un sistema di autocontrollo che ogni operatore nel settore della produzione e somministrazione di alimenti deve mettere in atto al fine di valutare e stimare pericoli e rischi e stabilire misure di controllo per prevenire l’insorgere di problemi igienici e sanitari.<br> Non avere un <b>piano di autocontrollo HACCP</b> secondo il Regolamento CE n. 852/2004 è reato punito con una sanzione di ca <b>2000,00 €.</b> <br><br>ING GROUP offre la consulenza per:<br><br> <ul><li>Redazione del Manuale di Autocontrollo, risultante dalla valutazione del processo produttivo</li> <li>Elaborazione delle specifiche procedure operative del sistema di autocontrollo</li> <li>Verifica periodica della corretta applicazione del sistema di autocontrollo.</li> <li>Elaborazione del Programma di corretta sanificazione delle attrezzature e degli ambienti di lavoro.</li> <li>Prelievi di campioni ed analisi di laboratorio</li> <li>Assistenza continuativa</li> <li>Assistenza in caso di verifica da parte degli enti di vigilanza</li>'
  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
